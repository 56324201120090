
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace, Getter } from 'vuex-class';
import { UserPermissions, FeaturesList } from "@/store/types";
import EventRecords from './EventRecords.vue'
import Multiselect from 'vue-multiselect';
import api from '../services/api.ccure.service';
import { retry } from 'rxjs/operators';
import { get } from 'lodash';

const SiteMonitor = namespace('siteMonitor');
const Root = namespace('');

@Component({
	components: {
		'event-records': EventRecords,
		'multiselect': Multiselect
	},
})
export default class EventRecordsCCure extends Vue {
	@Prop(Number) eventid?: number;

	@SiteMonitor.Getter('getSelectedEventRecord') selectedRecord: any;
	@SiteMonitor.Getter('getEventRecords') getEventRecords: any;
	@SiteMonitor.Getter filterIndex: any;

	@Getter isFullUser: any;
	@Getter("getFeaturesList") featuresList: FeaturesList;

	private logMessage: string = "";
	private postingMessage: boolean = false;

	private logMessageAll: string = "";
	private postingMessageAll: boolean = false;

	private postingMessageError: string | null = null;
	private dismissCountDown: number = 0;

	private selectedCCureAlarm: any = null;
	private anyCCure: boolean = false;

	private logTypes: any[] = [
		{ logTypeID: -1, title: "[Custom Message]" },
		{ logTypeID: 3, title: "1-Dispatch FC/PD" },
		{ logTypeID: 4, title: "1-Dispatch Facilities" },
		{ logTypeID: 5, title: "1-Contact Responsible Party" },
		{ logTypeID: 6, title: "2-Review Recorded Video" },
		{ logTypeID: 7, title: "2-Arrived / Investigating Locally" },
		{ logTypeID: 8, title: "6-Disposition: Area Secure / Situation Normal" },
		{ logTypeID: 9, title: "6-Disposition: Situation in progress" },
		{ logTypeID: 10, title: "8-Action: Incident Report Filed" },
		{ logTypeID: 11, title: "8-Action: Task Created" }
	]

	private logType: any = this.logTypes[0];
	private logTypeAll: any = this.logTypes[0];

	private get showCCureBar() {
		return this.filterIndex <= 0;
	}

	private created() {
		if (this.getEventRecords != null) {
			let index = this.getEventRecords.findIndex((record: any) => this.isCCure(record.serverTypeID));

			if (index > -1) {
				this.anyCCure = true;
			} else {
				let unwatch = this.$store.watch(state => state.siteMonitor.EventRecords, (value: any[], oldValue: any[]) => {
					if (value != null) {
						let index = value.findIndex((record) => this.isCCure(record.serverTypeID));

						if (index > -1) {
							this.anyCCure = true;
							unwatch();
						}
					}
				});
			}
		}
	}

	private isCCure(serverTypeID: number) {
		return serverTypeID == 427 || serverTypeID == 1234;
	}

	private get selectedEventRecordID() {
		if (this.selectedRecord == null) {
			return 0;
		}

		return this.selectedRecord.eventRecordID;
	}

	private get ccureUIEnabled() {
		return get(this.featuresList, ["Integration", "CCure", "UI"]);
	}

	private get logAllEnabled() {
		return get(this.featuresList, ["Integration", "CCure", "UI", "LogAll"]);
	}

	private get logSingleEnabled() {
		return get(this.featuresList, ["Integration", "CCure", "UI", "LogSingle"]);
	}

	@Watch("selectedEventRecordID")
	private onSelectedEventRecordIDChange(value: any, newValue: any) {
		if (value != null) {
			let refs = this.$refs;

			Vue.nextTick(() => {
				if (this.$refs["logSelect" + value] != null) {
					(this.$refs["logSelect" + value] as any).$el.focus();
				}
			});
		}
	}

	private postLogMessage(eventRecord: any) {
		if (this.postingMessage) {
			return;
		}

		if (!this.canPostMessage(this.logType, this.logMessage)) {
			return;
		}

		this.postingMessage = true;

		api.postLogMessage(eventRecord.eventRecordID,
			this.logType.logTypeID == -1 ? "" : this.logType.title,
			this.logMessage,
			this.eventid
		).then((response) => {
			this.logMessage = "";
			this.logType = {};

			this.postingMessage = false;
		}, (error) => {
			this.postingMessage = false;
			this.postingMessageError = error.message;
			this.dismissCountDown = 5;
		});
	}

	private postLogMessageAll() {
		if (this.postingMessageAll) {
			return;
		}

		if (!this.canPostMessage(this.logTypeAll, this.logMessageAll)) {
			return;
		}

		this.postingMessageAll = true;

		if (this.selectedCCureAlarm) {
			api.postLogMessage(this.selectedCCureAlarm.eventRecordID,
				this.logTypeAll.logTypeID == -1 ? "" : this.logTypeAll.title,
				this.logMessageAll,
				this.eventid
			).then((response) => {
				this.logMessageAll = "";
				this.logTypeAll = {};

				this.postingMessageAll = false;
			}, (error) => {
				this.postingMessageAll = false;
				this.postingMessageError = error.message;
				this.dismissCountDown = 5;
			});
		} else {
			api.postLogMessageAll(this.logTypeAll.logTypeID == -1 ? "" : this.logTypeAll.title,
			this.logMessageAll, this.eventid).then((response) => {
				this.logMessageAll = "";
				this.logTypeAll = {};

				this.postingMessageAll = false;
			}, (error) => {
				this.postingMessageAll = false;
				this.postingMessageError = error.message;
				this.dismissCountDown = 5;
			});
		}
	}

	public resetActions(eventRecord: any) {
		if (eventRecord === null || eventRecord === undefined) {
			api.resetAllActions(this.eventid);
		} else {
			api.resetActions(eventRecord.eventRecordID);
		}
	}

	private logTypeSelected(eventRecord: any, logType: any) {
		if (logType != null) {
			this.logType = logType;
		}

		setTimeout(() => {
			if (eventRecord == null) {
				(this.$refs['logMessage0'] as any).focus();
			} else {
				(this.$refs['logMessage' + eventRecord.eventRecordID] as any).focus();
			}
		}, 0.5 * this.$config.ANIMATION_DURATION);
	}

	private toggleExpandRecord(eventRecord: any) {
		this.selectedCCureAlarm = eventRecord;
		(this.$refs["logSelect0"] as any).$el.focus();
	}

	private canPostMessage(selectedType, logMessage) {
		if (selectedType == null || selectedType.logTypeID == undefined) {
			return false;
		}

		if (selectedType.logTypeID > 0) {
			return true;
		}

		if (selectedType.logTypeID == -1) {
			return logMessage.length > 0;
		}

		return false;
	}

	private countDownChanged(dismissCountDown) {
		this.dismissCountDown = dismissCountDown;
	}

	private clearSelectedCCureAlarm() {
		this.selectedCCureAlarm = null;
	}
}
